.header {
  display: flex;
  position: sticky;
  top: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f9f9f9;
  z-index: 1000;
  width: 100%;
}

.logo {
  font-size: 30px;
  font-weight: bold;
  color: #51ac15;
  font-family: 'SBAggroB';
  cursor: pointer;
}

.nav {
  display: flex;
}

.navItem {
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

@media (max-width: 768px) {
  .logo {
    font-size: 20px;
    padding-left: 5px;
  }

  .navItem {
    font-size: 16px;
    margin: 0;
  }

  .header {
    padding: 5px;
  }
}

@media (max-width: 370px) {
  .header {
    padding: 2px;
  }

  .navItem {
    padding: 10px 5px;
  }
}