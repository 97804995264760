.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.comingSoonText {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  text-align: center;
}
