.container {
  width: 100%;
  padding: 50px 200px;
}

.h2 {
  text-align: center;
  margin-top: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  align-items: center;
}

.inputGroup {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label {
  font-weight: 700;
}

.inputGroup input {
  width: 75%;
  padding: 5px;
  font-size: 16px;
  border-radius: 0;
}

.inputGroup input::placeholder {
  font-size: 12px;
  color: #aaa; 
}

.inputGroup input:focus {
  outline: 1px solid #000;
}

.inputGroup textarea {
  border-radius: 0;
  height: 200px;
  width: 75%;
  padding: 5px;
  font-size: 16px;
}

.inputGroup textarea:focus {
  outline: 1px solid #000;
}

.rating {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.widthBox{
  width: 75%;
}

.star {
  font-size: 30px;
  cursor: pointer;
  color: #ccc;
}

.star.filled {
  color: #ffcc00;
}

.submit {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #51ac15;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 30%;
  border-radius: 50px;
}

button:hover {
  background-color: #3a7910;
}

.genderCheckboxes {
  display: flex;
  width: 75%;
}

.genderLabel {
  display: flex;
  margin-right: 30px;
  align-items: center;
}

.genderLabel input {
  margin-right: 3px;
  accent-color: #51ac15;
}

.ageBox {
  padding: 5px;
  color: black;
  font-size: 16px;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .container {
    padding: 20px;
  }

  .ageBox {
    padding: 10px;
  }
}