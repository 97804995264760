.infoWindowContent {
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  box-sizing: border-box;
}

.infoWindowTitle {
  font-weight: bold;
  color: #333;
  font-size: 18px;
  margin-bottom: 5px;
}

.infoWindowText {
  font-size: 13px;
  color: #555;
  margin-bottom: 10px;
}

.infoWindowContent .separator {
  border-top: 1px solid #ddd;
  margin: 5px 0;
}

.infoWindowContent .infoRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.infoWindowContent .infoRow div {
  font-size: 12px;
  color: #888;
}

.titleAndCloseDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.closeImg {
  margin-bottom: 20px;
  margin-top: 10px;
  width: 12px; /* 이미지 크기 */
  height: 12px; /* 이미지 크기 */
  cursor: pointer;
  margin-left: 10px;
}

.locaBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9999; /* z-index를 높게 설정 */
  background-color: #51ac15;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 14px;
}

.findBtn {
  position: absolute;
  font-size: 14px;
  top: 60px;
  right: 10px;
  z-index: 9999; /* z-index를 높게 설정 */
  background-color: #51ac15;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}